import * as React from 'react'
import ReactDom from 'react-dom'
import {Picture} from './picture'

const handleTopClick = () => {
    window.scrollTo({
        top:0,
        left:0,
        behavior: 'smooth'
    })
}

const ToTopButton = (
    <div className='overlay-top' onClick={handleTopClick} >
        <Picture
            srcset={[{src: 'images/overlay/top.png', size: '1x'}, {src: 'images/overlay/top@2x.png', size: '2x'}]}
            alt='to top button' />
    </div>
)

const overlay = document.querySelector('.overlay')
if (overlay)
    window.addEventListener('scroll', e => {
        if(window.scrollY > 100){
            overlay.classList.remove('hidden')
        }else{
            overlay.classList.add('hidden')
        }
    })

const node = document.querySelector("#to-top-button")
if (node)
    ReactDom.render(ToTopButton, node)