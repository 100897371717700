import * as React from 'react'
const {hashfile} = require('../../client/src/hashfile')

type Src = {src: string, size: string}

type Props = {
    srcset: Src[],
    alt: string,
    srcsetMobile?: Src[],
    width?: string,
    height?: string,
    loading?: 'lazy' | 'eager'
    onClick?: () => void
    onLoad?: (ev: React.SyntheticEvent) => void
}

type WrapProps = {
    onClick?: () => void
    loading?: 'lazy' | 'eager'
}

const hashSrc = (set: Src[]) => {
    const webp:Src[] = []
    const jpeg:Src[] = []
    set.forEach(src => {
        const last = src.src.lastIndexOf('/')
        const path = src.src.slice(0, last)
        const name = src.src.slice(last + 1)
        webp.push({src: hashfile(path, name+'.webp'), size: src.size})
        jpeg.push({src: hashfile(path, name), size: src.size})
    })
    return [webp, jpeg]
}
const mimeType = (filename) => {
    const type = filename.substring(filename.lastIndexOf(".") + 1)
    return `image/${type !== 'jpg' ? type : 'jpeg'}`
}

const PictureWrapper: React.FC<WrapProps> = ({onClick, loading, children}) => 
    <picture onClick={onClick}>
        {loading === 'lazy'
            ? <noscript>{children}</noscript>
            : children}
    </picture>


export const Picture: React.FC<Props> = (props) => {
    const isMobile = !!props.srcsetMobile
    const [webp, jpeg] = hashSrc(props.srcset)
    const jsrcset = jpeg.map(src => `${src.src} ${src.size}`).join(', ')
    const wsrcset = webp.map(src => `${src.src} ${src.size}`).join(', ')
    const type = mimeType(props.srcset[0].src)
    const mobileSrcSet:{webp?:string, jpeg?:string} = {}
    
    if (props.srcsetMobile) {
        mobileSrcSet.webp = hashSrc(props.srcsetMobile!)[0].map(src => 
            `${src.src} ${src.size}`).join(', ')
        mobileSrcSet.jpeg = hashSrc(props.srcsetMobile!)[1].map(src => 
            `${src.src} ${src.size}`).join(', ')
    }
    
    return (
        <PictureWrapper onClick={props.onClick} loading={props.loading}>
            <source srcSet={wsrcset} type='image/webp' media={isMobile ? '(min-width: 851px)' : undefined}/>
            <source srcSet={jsrcset} type={type} media={isMobile ? '(min-width: 851px)' : undefined}/>
            {
                isMobile
                    ? [
                        <source srcSet={mobileSrcSet.webp} type='image/webp' media={isMobile ? '(min-width: 851px)' : undefined}/>,
                        <source srcSet={mobileSrcSet.jpeg} type={type} media={isMobile ? '(min-width: 851px)' : undefined}/>
                    ]
                    : null
            }
            <img src={jpeg[0].src} alt={props.alt} width={props.width} height={props.height} onLoad={props.onLoad} loading={props.loading} />
        </PictureWrapper>
    ) 
}